import { Link } from "gatsby";
import * as React from "react";
import Main from "../components/Main";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};
const headingAccentStyles = {
  color: "#663399",
};

const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
};

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: "16px",
  verticalAlign: "5%",
};

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
};

const docLink = {
  text: "Documentation",
  url: "https://www.gatsbyjs.com/docs/",
  color: "#8954A8",
};

// markup
const IndexPage = () => {
  return (
    <Main title="Home">
      {" "}
      <main style={pageStyles}>
        <title>Home Page</title>
        <h1 style={headingStyles}>
          Congratulations
          <br />
          <span style={headingAccentStyles}>
            — you just found a recipe site!{" "}
          </span>
          <span role="img" aria-label="Party popper emojis">
            🎉🎉🎉
          </span>
        </h1>
        <ul style={listStyles}>
          <li style={docLinkStyle}>
            <Link style={linkStyle} to="/recipes">
              See all Recipes
            </Link>
          </li>
        </ul>
      </main>
    </Main>
  );
};

export default IndexPage;
